<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2 mb-2">
      <kpi-health-coach-banner :patients="patientsFiltered" />
    </div>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2 space-x-1">
      <div class="relative w-1/4">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <el-select v-model="client" value-key="id" clearable placeholder="Client" filterable>
          <el-option v-for="client in clients" :value="client" :label="client.name" :key="client.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="health_coach" value-key="id" clearable placeholder="Health Coach" filterable>
          <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="pcp" value-key="id" clearable placeholder="PCP" filterable>
          <el-option v-for="pcp in pcps" :value="pcp" :label="`${pcp.first_name} ${pcp.last_name}`" :key="pcp.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="status" clearable placeholder="Status">
          <el-option v-for="status in statuses" :value="status.status" :key="status.status">
            <el-tooltip :content="status.description" placement="top">
              <span>{{status.status}}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="default" @click="refresh">Refresh</el-button>
      </div>
      <div>
        <el-button type="success" @click="resetNewPatient(); openDialogModal()">Register New Patient</el-button>
<!--        <button type="button" @click="refresh"-->
<!--          class="py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">-->
<!--          Refresh-->
<!--        </button>-->
<!--        <button type="button" @click="resetNewPatient(); openDialogModal()" class="green_button ml-3 px-2">-->
<!--          Register New Patient-->
<!--        </button>-->
      </div>
    </div>
    <v-data-table :loading="loading" :headers="dtheaders" :items="dtpatients" @click:row="goToPatient($event.id)" :search="search" sort-by="name" class="v-data-table cursor-pointer">
      <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
      <template v-slot:[`item.progress`]="{ item, index }">
        <v-menu open-on-hover :transition="false" top offset-x class="bg-opacity-0 bg-white">
          <template v-slot:activator="{ on, attrs, value }">
            <div v-on="on" class="p-2" @mouseenter="$refs['kpi-menu-' + item.id] && $refs['kpi-menu-' + item.id].getPatientDetails()">
              <v-progress-linear class="m-1" rounded :value="item.progress.rpm" :buffer-value="item.progress.target"></v-progress-linear>
              <v-progress-linear class="m-1" rounded :value="item.progress.ccm" :buffer-value="item.progress.target"></v-progress-linear>
            </div>
          </template>
          <div class="bg-opacity-1 bg-white p-2">
            <div class="flex justify-center">{{item.name}}</div>
            <task-duration-kpi-chart-small :ref="getRefName('kpi-menu', item.id)"  :patient="item"/>
            <!--            <div class="p-1">rpm:&nbsp;{{item.progress.rpm}}%</div>-->
            <!--            <div class="p-1">ccm:&nbsp;{{item.progress.ccm}}%</div>-->
            <!--            <div class="p-1">target:&nbsp;{{item.progress.target}}%</div>-->
          </div>
        </v-menu>
      </template>
<!--      <template v-slot:header.client="{ header }">-->
<!--        {{ header.text }}-->
<!--      </template>-->
      <template v-slot:[`item.name`]="{ item }">
        <button @click.stop="goToPatient(item.id, true)"><v-icon small>mdi-open-in-new</v-icon></button>
        {{ item.name }}
      </template>
      <template v-slot:[`item.last_active`]="{ item }">
        {{ item.last_active | date }}
      </template>
      <template v-slot:[`item.rpm_date`]="{ item }">
        {{ item.rpm_date | date }}
      </template>
      <template v-slot:[`item.ccm_date`]="{ item }">
        {{ item.ccm_date | date }}
      </template>
      <template v-slot:[`item.dob`]="{ item }">
        {{ item.dob | dob }}
      </template>
      <template v-slot:[`item.agreed_to_client_form`]="{ item }">
        {{ item.agreed_to_client_form | date }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <patient-actions :key="item.id" :patient="patients.patients.find(patient => patient.id === item.id)" @showEditPatient="showEditPatient" @showCreateRpm="showCreateRpm" @showCreateCcm="showCreateCcm" @showTos="showTos"/>
      </template>
    </v-data-table>
    <el-dialog :close-on-click-modal="false" :title="eligibilityModalTitle" :visible.sync="eligibilityModalVisible" width="50%"
      @close="closeEligibilityModal()">
      <div class="dialog__body" ref="eligibilityModalRef">
        <RpmDashboardErrors v-bind:errors="eligibilityErrors" v-bind:title="'Eligibility Request Errors'">
        </RpmDashboardErrors>

        <div class="grid grid-cols-2 gap-4">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="payer_code">
              Payer Code <span class="required">*</span>
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="payer_code" type="text" placeholder="" v-model="eligibilityPayerCode" />
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">
              Member ID <span class="required">*</span>
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="member_id" type="text" placeholder="" v-model="eligibilityMemberId" />
          </div>
          <el-button @click="eligibilityRequest" type="primary">Run Eligibility</el-button>
        </div>

        <div v-if="eligibilityModalResponse">
          <json-view v-show="eligibilityModalResponse" :data="eligibilityModalResponse" rootKey="coverages" :maxDepth="2"
            class="text-base" :styles="{ key: '#48bfe3' }" />
        </div>
        <div v-else>
          <p class="text-lg">No eligibility ran.</p>
        </div>
      </div>
    </el-dialog>

    <!-- Step Panel Modals -->
    <el-dialog :close-on-click-modal="false" :title="panelModalTitle" :visible.sync="dialogVisible" width="50%" class="josefin_font"
      @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="panelRegister" v-show="panelRegister">
          <RpmDashboardErrors v-bind:errors="registerErrors" v-bind:title="'Registration Validation Errors'">
          </RpmDashboardErrors>
          <Patient :patient="newPatient" :statuses="statuses" :auth-user="this.patients.authUser" :payers="this.patients.payers" />
        </div>
      </div>
      <div v-if="panelEligibility" class="panel__eligibility">
        <div class="dialog__body">
          <div v-if="eligibilityStepModalResponse">
            <json-view :data="eligibilityStepModalResponse" rootKey="coverages" :maxDepth="2" class="text-base"
              :styles="{ key: '#48bfe3' }" />
          </div>
          <div v-else>
            <p class="text-lg">No eligibility ran.</p>
          </div>
        </div>
      </div>
      <div v-if="panelAgreement" class="panel__agreement" ref="panelAgreement">
        <RpmDashboardErrors v-bind:errors="agreementErrors" v-bind:title="'Agreement Errors'"></RpmDashboardErrors>
        <Agreement :patient="newPatient" :legal-form="this.patients.legalForm" :auth-user="this.patients.authUser" :legal-check="legalCheck" />
      </div>
      <div ref="panelRpmCarePlan" v-if="panelRpmCarePlan" class="panel__rpm_careplan">
        <RpmDashboardErrors v-bind:errors="rpmErrors" v-bind:title="'RPM Validation Errors'"></RpmDashboardErrors>

        <div class="dialog__body">
          <RpmCarePlan v-model="carePlan" :templates="rpmCarePlanTemplates"  :patient="newPatient" />
        </div>
      </div>

      <div ref="panelCcmCarePlan" v-if="panelCcmCarePlan" class="panel__ccm_careplan">
        <RpmDashboardErrors v-bind:errors="ccmErrors" v-bind:title="'CCM Validation Errors'"></RpmDashboardErrors>

        <div class="dialog__body">
          <CcmCarePlan v-model="carePlan" :templates="ccmCarePlanTemplates" :patient="newPatient" />
        </div>
      </div>

      <div ref="panelOrderForm" v-if="panelOrderForm" class="panel__order_form">
        <RpmDashboardErrors v-bind:errors="orderFormErrors" v-bind:title="'Order Form Errors'"></RpmDashboardErrors>
        <order-form :patient="createdPatient" @hide-order-form="closeDialogModal" />
      </div>

      <span v-if="panelRegister" slot="footer" class="josefin dialog-footer">
        <el-button @click="createNewPatient" type="primary" :disabled="loading">Register patient</el-button>
      </span>
      <span v-if="panelEligibility" slot="footer" class="josefin dialog-footer">
        <el-button @click="stepPanelAgreement" type="primary">Agreement</el-button>
      </span>
      <span v-if="panelRpmCarePlan" slot="footer" class="josefin dialog-footer">
        <el-button @click="saveRpmCarePlan" type="primary" :disabled="loading">Save RPM Care Plan</el-button>
        <el-button @click="stepPanelCcmCarePlan" type="secondary">Skip to CCM Care Plan</el-button>
      </span>
      <span v-if="panelCcmCarePlan" slot="footer" class="josefin dialog-footer">
        <el-button @click="saveCcmCarePlan" type="primary" :disabled="loading">Save CCM Care Plan</el-button>
        <el-button @click="cancelCcmCarePlan" type="cancel">Cancel</el-button>
      </span>
      <span v-if="panelAgreement" slot="footer" class="josefin dialog-footer">
        <div class="flex justify-between items-center">
          <a class="btn__back btn__back-container" @click="stepPanelEligibility">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 12L6 8L10 4" stroke="#292929" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Back
          </a>
          <div class="flex">
            <el-button @click="closeDialogModal" class="btn__decline">DECLINE</el-button>
            <el-button @click="agreeToLegal" :disabled="!this.legalChecked || loading" type="primary">Accept</el-button>
          </div>
        </div>
      </span>
    </el-dialog>

    <!-- Patient Actions Modals -->
    <el-dialog :close-on-click-modal="false" :title="'Edit Patient'" :visible.sync="patientActionsEdit" width="50%" class="josefin_font"
      @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="patientEditRef">
          <RpmDashboardErrors v-bind:errors="actionPatientErrors" v-bind:title="'Edit Patient Errors'"></RpmDashboardErrors>
          <Patient :patient="patientActionSelected" :statuses="statuses" :hide_insurance="true" :payers="this.patients.payers" :auth-user="this.patients.authUser" />
        </div>
      </div>
      <el-button @click="editPatient" type="primary" :disabled="loading">Update patient</el-button>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="'Create RPM'" :visible.sync="patientActionsRpmCreate" width="50%" class="josefin_font"
               @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="rpmCreateRef">
          <RpmDashboardErrors v-bind:errors="rpmErrors" v-bind:title="'Create RPM Errors'"></RpmDashboardErrors>
          <RpmCarePlan v-model="carePlan" :templates="rpmCarePlanTemplates" :patient="patientActionSelected" />
        </div>
      </div>
      <el-button @click="createRpm" type="primary" :disabled="loading">Create RPM</el-button>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="'Create CCM'" :visible.sync="patientActionsCcmCreate" width="50%" class="josefin_font"
               @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="ccmCreateRef">
          <CcmDashboardErrors v-bind:errors="ccmErrors" v-bind:title="'Create CCM Errors'"></CcmDashboardErrors>
          <CcmCarePlan v-model="carePlan" :templates="ccmCarePlanTemplates" :patient="patientActionSelected" />
        </div>
      </div>
      <el-button @click="createCcm" type="primary" :disabled="loading">Create CCM</el-button>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :title="'TOS'" :visible.sync="patientActionsTos" width="50%" class="josefin_font"
               @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="agreementRef">
          <RpmDashboardErrors v-bind:errors="agreementErrors" v-bind:title="'Agreement Errors'"/>
          <Agreement :patient="patientActionSelected" :legal-form="this.patients.legalForm" :auth-user="this.patients.authUser" :legal-check="legalCheck" />
        </div>
      </div>
      <el-button @click="agreeToLegalAction" :disabled="!this.legalChecked || loading" type="primary">Accept</el-button>
    </el-dialog>
  </rpm-layout>
</template>


<script>
import RpmCarePlan from "@/components/RpmCarePlan.vue";
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import {JSONView} from "vue-json-component";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Patient from "@/components/Patient.vue";
import CcmCarePlan from "@/components/CcmCarePlan.vue";
import RpmLayout from '../../layouts/RpmLayout.vue';
import RpmSearch from "@/components/RpmSearch.vue";
import CcmDashboardErrors from "@/components/CcmDashboardErrors.vue";
import PatientMixin from "@/mixins/Patient.js";
import Agreement from "@/components/Agreement.vue";
import Template from "@/mixins/Template";
import OrderForm from "@/components/OrderForm.vue";
import statuses from '@/helpers/patientStatuses.js';
import Chart from '@/components/Chart.vue';
import TaskDurationKpiChartSmall from "@/views/rpm/components/TaskDurationKpiChartSmall.vue";
import KpiHealthCoachBanner from "@/components/KpiHealthCoachBanner.vue";

Vue.component("patient-actions", {
  props: ["patient"],
  data() {
    return {
      patientDetails: {},
    }
  },
  methods: {
    async getPatientDetails(id) {
      return Vue.$http
          .get("/api/rpm_ccm_dashboard/patient_details", {
            params: {
              patient_id: id,
              token: await this.$auth.getTokenSilently(),
            },
          })
          .then((res) => (this.patientDetails = res.data.data))
          .catch((error) => this.handleEhrApiErrors(error, this.errors));
    },
    async loadPatient() {
      await this.getPatientDetails(this.patient.id);
    },
    async showEditPatient() {
      await this.loadPatient();
      this.$emit('showEditPatient', this.patientDetails);
    },
    async showCreateRpm() {
      await this.loadPatient();
      this.$emit('showCreateRpm', this.patientDetails);
    },
    async showCreateCcm() {
      await this.loadPatient();
      this.$emit('showCreateCcm', this.patientDetails);
    },
    async showTos() {
      await this.loadPatient();
      this.$emit('showTos', this.patientDetails);
    },
  },
  template: `
    <div class="relative">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <svg class="w-6 h-6 ico__action" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
            </svg>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="showEditPatient">
            <v-list-item-title>Edit Patient</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showCreateRpm">
            <v-list-item-title>Create RPM</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showCreateCcm">
            <v-list-item-title>Create CCM</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showTos">
            <v-list-item-title>TOS</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  `,
});

export default {
  mixins: [PatientMixin],
  components: {
    KpiHealthCoachBanner,
    TaskDurationKpiChartSmall,
    Chart,
    OrderForm,
    Template,
    Agreement,
    CcmDashboardErrors,
    RpmSearch, CcmCarePlan, RpmCarePlan, Patient, RpmDashboardErrors, "json-view": JSONView, RpmLayout },
  data() {
    return {
      loading: true,
      patientActionSelected: {},
      patientActionsEdit: false,
      patientActionsRpmCreate: false,
      patientActionsCcmCreate: false,
      patientActionsTos: false,
      actionPatientErrors: {},
      eligibilityPayerCode: '',
      eligibilityMemberId: '',
      openTab: 1,
      registerErrors: [],
      rpmErrors: [],
      ccmErrors: [],
      orderFormErrors: [],
      dialogVisible: false,
      panelRegister: true,
      currentPanel: "register",
      panelEligibility: false,
      panelAgreement: false,
      panelRpmCarePlan: false,
      panelCcmCarePlan: false,
      panelOrderForm: false,
      dialogAction: false,
      dialogActionArchived: false,
      dialogAction2: false,
      panelModalTitle: 'New Patient Onboarding (CCM/RPM) — 1/5',
      eligibilityModalTitle: 'Eligibility Request',
      eligibilityModalVisible: false,
      eligibilityPatientId: null,
      eligibilityModalResponse: null,
      eligibilityStepModalResponse: null,
      eligibilityErrors: [],
      resolveVitalAlertErrors: [],
      agreementErrors: [],
      resolveVitalAlertModalVisible: false,
      resolveVitalAlert: {
        care_plan: {
          notes: "",
          alerts: [
            {
              type: "",
              min: "",
              max: "",
            },
          ],
        },
        vital_reading: {
          attribute: "",
          value: "",
        },
      },
      resolveVitalAlertPatient: {},
      resolveVitalAlertAction: "",
      carePlan: [],
      search: "",
      newPatient: {
        primary_client_id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        sex: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country_id: "1",
        member_id: "",
        member_group_id: "",
        payer_id: "",
        // payer_code: "",
      },
      createdPatient: {},
      legalCheck: {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      },
      accessToken: null,
      client: null,
      pcp: null,
      health_coach: null,
      status: 'active',
      statuses: statuses,
    };
  },
  created: async function () {
    await this.getAccessToken();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser').then(this.resetFilters) : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
      !this.$store.state.patients.payers.length ? this.$store.dispatch('getPayers') : null,
      !this.$store.state.patients.templates.length ? this.$store.dispatch('getTemplates') : null,
      !this.$store.state.patients.legalForm.text ? this.$store.dispatch('getLegalForm') : null,
    ]).finally(() => {
      this.loading = false;
      this.resetFilters();
    });
  },
  computed: {
    ...mapState(["patients"]),
    authUser() {
      return this.$store.state.patients.authUser
    },
    healthCoaches() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach'));
    },
    pcps() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider'));
    },
    patientsFiltered() {
      let patients = this.patients.patients;
      if (this.client) {
        patients = patients.filter((p) => p.primary_client_id === this.client.id);
      }
      if (this.health_coach) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.care_manager === this.health_coach.id);
      }
      if (this.pcp) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.pcp === this.pcp.id);
      }
      if (this.status) {
        patients = patients.filter((p) => p.status === this.status);
      }
      return patients;
    },
    dtheaders() {
      return [
        {text: 'Progress', value: 'progress'},
        {text: 'LAD', value: 'last_active'},
        {text: 'Name', value: 'name'},
        {text: 'PCP', value: 'pcp'},
        {text: 'Health Coach', value: 'care_manager'},
        {text: 'Client', value: 'client', width: 200},
        {text: 'RPM', value: 'rpm_date', width: 80},
        {text: 'CCM', value: 'ccm_date', width: 80},
        {text: 'DOB', value: 'dob', width: 80},
        {text: 'Cell', value: 'phone', width: 140},
        {text: 'Email', value: 'email', width: 80},
        {text: 'TOS', value: 'agreed_to_client_form', width: 80},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions', sortable: false},
      ]
    },
    dtpatients() {
      return this.patientsFiltered.map((patient) => ({
        ...patient,
        name: `${patient.first_name} ${patient.last_name}`,
        last_active: patient.cache.last_active,
        pcp: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.pcp) : "None",
        care_manager: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.care_manager) : "None",
        client: this.$store.getters.clientName(patient.primary_client_id),
        rpm_date: patient.cache.rpm_date,
        ccm_date: patient.cache.ccm_date,
        phone: [patient.phone, patient.cell].join('\n'),
        progress: {
          rpm: Math.min(Math.round(100 * (patient.cache.task_duration_rpm_sum_monthly || 0) / this.authUser.config.task_dur_goal), 100),
          ccm: Math.round(100 * (patient.cache.task_duration_ccm_sum_monthly || 0) / this.authUser.config.task_dur_goal),
          target: Math.round(((new Date().getDate()) / (new Date((new Date().getFullYear()), (new Date().getMonth() +1), 0)).getDate()) * 100, 2)
        },
      }))
    },
    clients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    legalChecked: function () {
      return this.legalCheck.firstContact && this.legalCheck.cellService && this.legalCheck.dailyCommitment && this.legalCheck.reviewTos;
    },
    templates: function () {
      return this.patients.templates;
    },
    rpmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'rpm_care_plan');
    },
    ccmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'ccm_care_plan');
    },
  },
  methods: {
    ...mapActions(['getPatients', 'getTemplates', 'getLegalForm', 'getAuthUser']),
    getRefName(baseName, index) {
      return `${baseName}-${index}`;
    },
    resetFilters() {
      this.status = 'active';
      if (this.authUser.behaviors.includes('is_provider') && this.pcps.some(pcp => pcp.id === this.authUser.id)) {
        this.pcp = this.authUser;
      } else if (this.healthCoaches.some(hc => hc.id === this.authUser.id)) {
        this.health_coach = this.authUser;
      }
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('getPatients').finally(() => this.loading = false);
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
    showActionPatientModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsEdit = true;
    },
    showActionRpmModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsRpmCreate = true;
    },
    showActionCcmModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsCcmCreate = true;
    },
    showActionTosModal(selectedPatient) {
      this.patientActionSelected = selectedPatient;
      this.patientActionsTos = true;
    },
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    openDialogModal() {
      this.openStepPanel();
      this.dialogVisible = true;
    },
    closeDialogModal() {
      this.dialogVisible = false;
      this.closeStepPanels();
    },
    openEligibilityModal(patientKey, patientId) {
      let wavelandResponse = [];

      this.eligibilityErrors = {};
      this.eligibilityMemberId = '';
      this.eligibilityPayerCode = '';

      if (this.patients.patients[patientKey]['waveland_responses'].length > 0) {
        if (this.patients.patients[patientKey]['waveland_responses'][0]['response']['foundCoverages'].length > 0) {
          let coverages = [];
          coverages = this.patients.patients[patientKey]['waveland_responses'][0]['response']['foundCoverages'];

          for (let key in coverages) {
            wavelandResponse.push(coverages[key]);
          }
        }

        this.eligibilityModalResponse = wavelandResponse;
      }

      this.eligibilityPatientId = patientId;
      this.eligibilityModalVisible = true;
    },
    closeEligibilityModal() {
      this.eligibilityModalVisible = false;
      this.eligibilityModalResponse = null;
    },
    eligibilityRequest() {
      if (!this.checkEligibilityRequestForm()) {
        this.$nextTick(() => {
          this.$refs.eligibilityModalRef.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/eligibility_request`, { patient_id: this.eligibilityPatientId, payer_code: this.eligibilityPayerCode, member_id: this.eligibilityMemberId }, { params: { token: this.accessToken } }
      ).then((response) => {
        this.eligibilityModalResponse = response.data.data;
        this.$store.dispatch('getPatients');
        this.$awn.success("Eligibility Request Ran Successfully");
      }).catch((error) => this.handleEhrApiErrors(error, this.eligibilityErrors, this.$refs.eligibilityModalRef)).finally(() => {
        this.loading = false;
      });
    },
    saveRpmCarePlan() {
      if (!this.checkRpmForm()) {
        this.$nextTick(() => {
          this.$refs.panelRpmCarePlan.scrollIntoView();
        });
        return;
      }

      let filteredAlerts = [];
      for (const key in this.carePlan.alerts) {
        const alert = this.carePlan.alerts[key];
        if (alert.min && alert.max && alert.frequency) {
          filteredAlerts.push(alert);
        }
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.createdPatient.id, alerts: JSON.stringify(filteredAlerts), type: 'rpm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then((res) => {
        this.createdPatient.care_plans.push(res.data.data);
        this.stepPanelCcmCarePlan();
      })
        .catch((error) =>
          this.handleEhrApiErrors(
            error,
            this.rpmErrors,
            this.$refs.panelRpmCarePlan
          )
        ).finally(() => {
          this.loading = false;
        });
    },
    saveCcmCarePlan() {
      if (!this.checkCcmForm()) {
        this.$nextTick(() => {
          this.$refs.panelCcmCarePlan.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.createdPatient.id, type: 'ccm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then((res) => {
        this.createdPatient.care_plans.push(res.data.data);
        this.stepPanelOrderForm();
      })
        .catch((error) =>
          this.handleEhrApiErrors(
            error,
            this.ccmErrors,
            this.$refs.panelCcmCarePlan
          )
        ).finally(() => {
          this.loading = false;
        });
    },
    cancelCcmCarePlan() {
      this.closeDialogModal();
      this.closeStepPanels();
      this.resetNewPatient();
    },
    createNewPatient() {
      if (!this.checkRegisterForm()) {
        this.$nextTick(() => {
          this.$refs.panelRegister.scrollIntoView();
        });

        return;
      }

      this.loading = true;

      Vue.$http
        .post(`/api/rpm_ccm_dashboard/create_patient`, this.newPatient, {
          params: { token: this.accessToken },
        })
        .then((response) => {
          let data = response.data.data;

          this.createdPatient = data;
          this.createdPatient.care_plans = [];

          if (data.waveland_responses && data.waveland_responses.length) {
            this.eligibilityStepModalResponse = data.waveland_responses[0];
          }

          this.$store.dispatch("getPatients");
          // this.stepPanelEligibility();
          this.stepPanelAgreement();
        })
        .catch((error) =>
          this.handleEhrApiErrors(
            error,
            this.registerErrors,
            this.$refs.panelRegister
          )
        ).finally(() => {
          this.loading = false;
        });
    },
    editPatient() {
      if (!this.checkPatientEditForm()) {
        this.$nextTick(() => {
          this.$refs.patientEditRef.scrollIntoView();
        });

        return;
      }

      this.loading = true;

      const editPatient = {
        id: this.patientActionSelected.id,
        primary_client_id: this.patientActionSelected.primary_client_id,
        status: this.patientActionSelected.status,
        first_name: this.patientActionSelected.first_name,
        middle_name: this.patientActionSelected.middle_name,
        last_name: this.patientActionSelected.last_name,
        email: this.patientActionSelected.email,
        dob: this.patientActionSelected.dob,
        sex: this.patientActionSelected.sex,
        phone: this.patientActionSelected.phone,
        cell: this.patientActionSelected.cell,
        address1: this.patientActionSelected.address1,
        address2: this.patientActionSelected.address2,
        city: this.patientActionSelected.city,
        state: this.patientActionSelected.state,
        zip: this.patientActionSelected.zip,
        country_id: 1,
        member_id: this.patientActionSelected.member_id,
        member_group_id: this.patientActionSelected.member_group_id,
        payer_id: this.patientActionSelected.payer_id,
      };

      Vue.$http
        .put(`/api/rpm_ccm_dashboard/update_patient`, editPatient, {
          params: { token: this.accessToken },
        })
        .then((res) => {
          this.patientActionSelected = res.data.data;
          this.$store.dispatch("getPatients");
          this.$awn.success("Patient Successfully Edited");
        })
        .catch((error) =>
          this.handleEhrApiErrors(
            error,
            this.actionPatientErrors,
            this.$refs.patientEditRef
          )
        ).finally(() => {
          this.loading = false;
        });
    },
    createRpm() {
      if (!this.checkRpmForm()) {
        this.$nextTick(() => {
          this.$refs.rpmCreateRef.scrollIntoView();
        });

        return;
      }

      this.loading = true;

      let filteredAlerts = [];
      for (const key in this.carePlan.alerts) {
        const alert = this.carePlan.alerts[key];
        if (alert.min && alert.max && alert.frequency) {
          filteredAlerts.push(alert);
        }
      }

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.patientActionSelected.id, alerts: JSON.stringify(filteredAlerts), type: 'rpm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then(() => {
        this.$awn.success("Rpm Care Plan Created");
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.rpmErrors,
                  this.$refs.rpmCreateRef
              )
          ).finally(() => {
            this.loading = false;
          });
    },
    createCcm() {
      if (!this.checkCcmForm()) {
        this.$nextTick(() => {
          this.$refs.ccmCreateRef.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, { patient_id: this.patientActionSelected.id, type: 'ccm', notes: this.carePlan.notes, health_condition: this.carePlan.health_condition, start_date: this.carePlan.start_date, icd_codes: this.carePlan.icd_codes }, { params: { token: this.accessToken } }
      ).then(() => {
        this.$awn.success("CCM Care Plan Created");
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.ccmErrors,
                  this.$refs.ccmCreateRef
              )
          ).finally(() => {
            this.loading = false;
          });
    },
    handleEhrApiErrors(errorResponse, vueErrors, ref) {
      let response = errorResponse.response.data;
      if (response.errors && response.errors.length > 0) {
        for (const key in response.errors) {
          if (response.errors.hasOwnProperty(key)) {
            for (const fieldKey in response.errors[key]) {
              if (response.errors[key].hasOwnProperty(fieldKey)) {
                vueErrors.push(key + ": " + response.errors[key][fieldKey]);
              }
            }
          }
        }
      } else {
        vueErrors.push(response.message);
      }
      this.$nextTick(() => {
        ref.scrollIntoView();
      });
    },
    agreeToLegalAction() {
      this.loading = true;

      Vue.$http
          .post(
              `/api/rpm_ccm_dashboard/agree_to_legal`,
              {},
              {
                params: {
                  patient_id: this.patientActionSelected.id,
                  token: this.accessToken
                },
              }
          )
          .then(() => {
            this.$store.dispatch("getPatients");
            this.$awn.success("Patient Successfully Agreed to Legal");
            this.patientActionsTos = false;
          })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.agreementErrors,
                  this.$refs.agreementRef
              )
          ).finally(() => {
            this.loading = false;
          });
    },
    agreeToLegal() {
      this.loading = true;

      Vue.$http
        .post(
          `/api/rpm_ccm_dashboard/agree_to_legal`,
          {},
          {
            params: {
              patient_id: this.createdPatient.id,
              token: this.accessToken
            },
          }
        )
        .then(() => {
          this.stepPanelRpmCarePlan();
        })
        .catch((error) =>
          this.handleEhrApiErrors(
            error,
            this.agreementErrors,
            this.$refs.panelAgreement
          )
        ).finally(() => {
          this.loading = false;
        });
    },
    resetNewPatient() {
      this.currentPanel = "register";
      this.newPatient = {
        primary_client_id: null,
        status: 'onboard',
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        sex: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country_id: "1",
        member_id: "",
        member_group_id: "",
        payer_id: "",
      };
      this.createdPatient = {};
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
      this.eligibilityStepModalResponse = null;
      this.resetCarePlan();
    },
    resetLegalCheck() {
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
    },
    resetCarePlan() {
      this.rpmErrors = [];
      this.ccmErrors = [];

      this.carePlan = {};
      this.carePlan.icd_codes = [];
      this.carePlan.alerts = [
        { type: "weight", min: -5, max: 5, min_warning: -4, max_warning: 4, frequency: "daily" },
        { type: "pulse", min: 50, max: 100, min_warning: 55, max_warning: 95, frequency: "daily" },
        { type: "systolic", min: 95, max: 160, min_warning: 102, max_warning: 153, frequency: "daily" },
        { type: "diastolic", min: 50, max: 90, min_warning: 54, max_warning: 86, frequency: "daily" },
        { type: "oxy", min: 90, max: 100, min_warning: 91, max_warning: 99, frequency: "daily" },
        { type: "peak_expiratory_flow", min: null, max: null, min_warning: null, max_warning: null, frequency: null },
        { type: "blood_glucose_nonfasting", min: 65, max: 280, min_warning: 87, max_warning: 258, frequency: "daily" },
        { type: "temperature", min: 97, max: 100, min_warning: 98, max_warning: 99, frequency: "daily" }
      ]
    },
    checkRpmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.rpmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });

      for (const key in this.carePlan.alerts) {
        let alert = this.carePlan.alerts[key];

        if (alert.min || alert.max || alert.frequency) {
          if (!(alert.min && alert.max && alert.frequency)) {
            this.rpmErrors.push(
              "Alert " +
              key +
              " needs to have min, max and frequency filled out."
            );
          }

          const min = parseFloat(alert.min);
          const max = parseFloat(alert.max);
          const min_warning = parseFloat(alert.min_warning);
          const max_warning = parseFloat(alert.max_warning);

          if (isNaN(min) || isNaN(max)) {
            this.rpmErrors.push(
              "Alert " + alert.type + " min/max need to be valid numbers."
            );
          } else if (min >= max) {
            this.rpmErrors.push(
              "Alert " + alert.type + " needs to have a valid min/max range."
            );
          }

          if (isNaN(min_warning) || isNaN(max_warning)) {
            this.rpmErrors.push(
                "Alert " + alert.type + " min warning/max warning need to be valid numbers."
            );
          } else if (min_warning >= max_warning) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          } else if (min_warning <= min || max_warning >= max) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          }
        }
      }

      return !this.rpmErrors.length;
    },
    checkCcmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.ccmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });
      return !this.ccmErrors.length;
    },
    checkEligibilityRequestForm() {
      const required = ['payer_code', 'member_id'];
      this.eligibilityErrors = this.checkForm(required, { payer_code: this.eligibilityPayerCode, member_id: this.eligibilityMemberId });
      return !this.eligibilityErrors.length;
    },
    checkRegisterForm() {
      const required = ['primary_client_id', 'state', 'first_name', 'status'];
      this.registerErrors = this.checkForm(required, this.newPatient);
      return !this.registerErrors.length;
    },
    checkPatientEditForm() {
      const required = [
        "state",
        "first_name",
        "status",
      ];
      this.actionPatientErrors = this.checkForm(
        required,
        this.patientActionSelected
      );
      return !this.actionPatientErrors.length;
    },
    checkForm(required, formValuesObj) {
      let errors = [];

      for (const key in required) {
        if (!formValuesObj.hasOwnProperty(required[key])) {
          errors.push(required[key].replace("_", " ") + " is required.");
        } else if (
          formValuesObj.hasOwnProperty(required[key]) &&
          required.indexOf(required[key]) !== -1 &&
          !formValuesObj[required[key]]
        ) {
          errors.push(required[key].replace("_", " ") + " is required.");
        }
      }

      return errors;
    },
    stepPanelRegister() {
      this.currentPanel = "register";
      this.openStepPanel();
    },
    stepPanelEligibility() {
      this.currentPanel = "eligibility";
      this.openStepPanel();
    },
    stepPanelAgreement() {
      this.currentPanel = "agreement";
      this.openStepPanel();
    },
    stepPanelRpmCarePlan() {
      // Build default care plan data
      this.resetCarePlan();

      this.currentPanel = "rpm";
      this.openStepPanel();
    },
    stepPanelCcmCarePlan() {
      // Build default care plan data with no alerts
      this.resetCarePlan();

      this.currentPanel = "ccm";
      this.openStepPanel();
    },
    stepPanelOrderForm() {
      this.currentPanel = "order-form";
      this.openStepPanel();
    },
    openStepPanel() {
      this.closeStepPanels();

      switch (this.currentPanel) {
        case "register":
          this.panelModalTitle = "New Patient Onboarding (CCM/RPM) — 1/5";
          this.panelRegister = true;
          break;
        case "agreement":
          this.panelModalTitle = "New Patient Onboarding (CCM/RPM) — 2/5";
          this.panelAgreement = true;
          break;
        case "rpm":
          this.panelModalTitle = "New Patient RPM Care Plan (CCM/RPM) — 3/5";
          this.panelRpmCarePlan = true;
          break;
        case "ccm":
          this.panelModalTitle = "New Patient CCM Care Plan (CCM/RPM) — 4/5";
          this.panelCcmCarePlan = true;
          break;
        case "order-form":
          this.panelModalTitle = "New Patient Order Form (CCM/RPM) — 5/5";
          this.panelOrderForm = true;
          break;
      }
    },
    closeStepPanels() {
      this.panelRegister = false;
      this.panelEligibility = false;
      this.panelAgreement = false;
      this.panelRpmCarePlan = false;
      this.panelCcmCarePlan = false;
    },
    showEditPatient(patient) {
      this.actionPatientErrors = [];
      this.resetNewPatient();
      this.showActionPatientModal(patient);
    },
    showCreateRpm(patient) {
      this.rpmErrors = [];
      this.resetCarePlan();
      this.showActionRpmModal(patient);
    },
    showCreateCcm(patient) {
      this.ccmErrors = [];
      this.resetCarePlan();
      this.showActionCcmModal(patient);
    },
    showTos(patient) {
      this.agreementErrors = [];
      this.resetLegalCheck();
      this.showActionTosModal(patient);
    },
  },
};
</script>


<style>
.green_button {
  padding: 0.25rem 0.75rem;
}
.v-data-table th {
  white-space: nowrap;
}
</style>
